export default class {
    constructor() {
        this.submitBtn = '.js-btn__confirm';
        this.policy = '#js-agreeCheck';
        this.agreeCheck();
    }
    agreeCheck() {
        const submitBtn = document.querySelector(this.submitBtn);
        const checkPolicy = document.querySelector(this.policy);
        if(submitBtn && checkPolicy) {
            const path = location.pathname;
            if(path === "/contact/" || path === "/request/" || path === "/reservation/" || path === "/download/") {
                submitBtn.disabled = true;
                checkPolicy.checked = false;
            }
            checkPolicy.addEventListener('change', () => {
                // e.preventDefault();
                if( checkPolicy.checked === true ) {
                    submitBtn.disabled = false;
                } else {
                    submitBtn.disabled = true;
                }
            })
        }
    }
}