import StickySidebar from 'sticky-sidebar'

export default class {
    constructor() {
        this.wrapper = '#js-sticky__wrapper';

        this.sidebar = '#js-sticky__side';
        this.sidebar_inner = '#js-sticky__side-inner';

        this.window_width = 1279;

        this.header = '#js-header';
        this.height;

        this.init(); 
    }
    init() {
        const wrapper = document.querySelector(this.wrapper);
        if(wrapper) {
            const header = document.querySelector(this.header);
            // wrapper.forEach( (wrapper) => {
            //     // const sidebar = wrapper.querySelector(this.sidebar);
            //     // const sidebar_inner = wrapper.querySelector(this.sidebar_inner);

            //     this.func = new StickySidebar(this.sidebar, {
            //         containerSelector: this.wrapper,
            //         innerWrapperSelector: this.sidebar_inner,
            //         // topSpacing: 100,
            //     })
            // });
            this.height = header.clientHeight + 20;
            // console.log(this.height);
            this.func = new StickySidebar(this.sidebar, {
                containerSelector: this.wrapper,
                innerWrapperSelector: this.sidebar_inner,
                topSpacing: this.height,
            })
            this.is_tablet(this.func)
            window.addEventListener("resize",() => {
                this.height = header.clientHeight + 20;
                this.is_tablet(this.func)
            })
        }
    }
    is_tablet(func){
        if( window.outerWidth < this.window_width ){
            func.destroy()
            
        }else{
            func.initialize()
        }
    }
}