import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class {
    constructor() {

        this.opened = false
        this.body = document.body;

        const bodyScrollLock = require('body-scroll-lock');
        // スクロールをロックする処理
        this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
        // ロックを解除する処理
        this.enableBodyScroll = bodyScrollLock.enableBodyScroll;
        // オプション
        this.options = {
            /**
             * bodyにスクロールバー分のpadding-leftを追加するか
             * default: false
             */
            reserveScrollBarGap: true,
        }
        /**
         * gnav
        */
        this.gnavBtn = "#js-gnav__btn";
        this.gnavList = "#js-gnav";
        this.gnav();

        /**
         * Hamburger
         */
        this.drawer_id = "#js-drawer";
        this.hamburger_id = "#js-drawer__hamburger";
        this.openClass = "is-open";
        this.logo_id = "#js-drawer__logo"
        this.init();

        /**
         * Hamburger
         */
         this.drawerItemLink = "#js-drawer a";
         this.currentPage()
    }
    currentPage(){
        const drawerItemLinks = document.querySelectorAll(this.drawerItemLink);
        drawerItemLinks.forEach( (el) => {
            el.addEventListener('click',(e)=>{
                this.close();
            })
        })
    }
    gnav() {
        this.gnavList = document.querySelector(this.gnavList);
        this.gnavBtn = document.querySelector(this.gnavBtn);
        if(this.gnavList && this.gnavBtn) {
            this.gnavBtn.addEventListener("click", (e) => {
                this.gnavBtnClick(e);
            })
        }
    } 
    gnavBtnClick(e){
        this.gnavToggleClass();
    }
    gnavToggleClass() {
        if(!this.opened){
            // this.open();
            this.gnavList.classList.add("is-active");
            this.gnavBtn.classList.add("is-active");
            this.opened = true;
         }else{
            this.gnavList.classList.remove("is-active");
            this.gnavBtn.classList.remove("is-active");
            this.opened = false;
         }
    }
    
    init() {
        this.drawer = document.querySelector(this.drawer_id);
        this.hamburger = document.querySelector(this.hamburger_id);
        this.logo = document.querySelector(this.logo_id);
        if(this.drawer) {
            if(this.hamburger) {
                this.hamburger.addEventListener('click', (e) => {
                    this.drawerClick(e);
                })
            }
        }
    }
    drawerClick(e) {
        this.drawerToggleClass();
    }
    drawerToggleClass() {
        if(!this.opened){
            this.open();
        }else{
            this.close();
        }
    }
    open() {
        // this.disableBodyScroll(this.body, this.options);
        this.drawer.style.display = 'block';
        setTimeout(() =>{
            this.drawer.setAttribute('aria-hidden', false);
            this.drawer.classList.add(this.openClass);
            this.logo.classList.add(this.openClass);
            this.hamburger.setAttribute('aria-expanded', true);
            this.hamburger.setAttribute('aria-label', 'メニューを閉じる');
            
            this.opened = true;
        }, 100);
    } 
    close() {
        // this.enableBodyScroll(this.body, this.options);
        
        this.drawer.setAttribute('aria-hidden', true);
        this.drawer.classList.remove(this.openClass);
        this.logo.classList.remove(this.openClass);
        this.hamburger.setAttribute('aria-expanded', false);
        this.hamburger.setAttribute('aria-label', 'メニューを開く');
        
        this.opened = false;
        setTimeout(() =>{
            this.drawer.style.display = 'none';
        }, 100)
    }
}