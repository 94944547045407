import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.heroSlider();
        this.recommendSlider();
        this.propertySlider();
    }
    heroSlider(){
        this.galleryID = "#js-slider__gallery";
        this.galleryNum = 3;

        const elems = [];
        for( let i = 0; i < this.galleryNum; i++ ) {
            const num = i + 1;
            const el = this.galleryID  + '0' + num;
            elems.push(el);
        }
        const galleries = document.querySelectorAll(elems);

        if( galleries.length > 0 ) {
            galleries.forEach( (gallery, index) => {
                if(index === 1) {
                    const swiper01 = new Swiper ( gallery, {
                        effect: 'slide',
                        direction: 'vertical',
                        mousewheel: true,
                        speed: 32000,
                        autoHeight: true,
                        autoplay: {
                            delay: 0,
                            disableOnInteraction: false,
                            reverseDirection: true,
                        },
                        slidesPerView: 2,
                        spaceBetween: 15,
                        loop: true,
                        delay: 1,
                        freeMode: true,
                        allowTouchMove: false,
                        mousewheel: false,
                        on: {
                            slideChangeTransitionStart: function() {
                                gallery.style.transitionTimingFunction = 'linear';
                            },
                            resize: function() {
                                swiper01.autoplay.start();
                            }
                        }
                    });
                } else {
                    const swiper02 = new Swiper ( gallery, {
                        effect: 'slide',
                        direction: 'vertical',
                        mousewheel: true,
                        speed: 32000,
                        autoHeight: true,
                        autoplay: {
                            delay: 0,
                            disableOnInteraction: false,
                        },
                        slidesPerView: 1,
                        spaceBetween: 15,
                        loop: true,
                        delay: 1,
                        freeMode: true,
                        allowTouchMove: false,
                        mousewheel: false,
                        on: {
                            slideChangeTransitionStart: function() {
                                gallery.style.transitionTimingFunction = 'linear';
                            },
                            resize: function() {
                                swiper02.autoplay.start();
                            }
                        }
                    });
                }
            });
        }
    }
    recommendSlider(){
        const recommendSlider = '#js-slider__recommend';
        new Swiper (recommendSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeOutExpo",
            // autoplay: {
            //     delay: 4000
            // },
            slidesPerView: 1.25,
            spaceBetween: 32,
            pagination: {
                el: "#js-slider__recommend--pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#js-slider__recommend--next",
                prevEl: "#js-slider__recommend--prev"
            },
            breakpoints: {
                1440: {
                    slidesPerView: 1.85,
                    spaceBetween: 60,
                }, 
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 40,
                }, 
            }
        })
    }
    propertySlider(){
        const propertySlider = '#js-slider__property';
        new Swiper (propertySlider, {
            effect:'slide',
            speed:1000,
            loop: true,
            easing: "easeOutExpo",
            autoplay: {
                delay: 4000
            },
            slidesPerView: 1.5,
            centeredSlides:true,
            spaceBetween: 16,
            pagination: {
                el: "#js-slider__property--pagination",
                clickable: true,
            },
            breakpoints: {
                1440: {
                    slidesPerView: 2.75,
                    spaceBetween: 60,
                }, 
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 40,
                }, 
            }
            // navigation: {
            //     nextEl: "#js-slider__recommend--next",
            //     prevEl: "#js-slider__recommend--prev"
            // },
        })
    }
}